import React from 'react';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ApiClient from '../ApiClient';
import ReactLoading from 'react-loading';
import './paymentCheckout.css';
import { SessionStatus, TransactionStatus } from "../common/constans";
import UTILS from '../common/utils';
import PoweredByBox from '../common/poweredByBox';


const { pay, getAuthenticationView, getAuthenticationStatus, getTransactionSessionInfo } = ApiClient();


class DirectPaymentCheckout extends React.Component {
    constructor(props) {
        super(props);
        let showNotFound = false;
        let params = new URLSearchParams(window.location.search);
        if (params.has('token')) {
            this.authToken = params.get('token');
        } else {
            showNotFound = true;
        }

        if (params.has('session_id')) {
            this.sessionId = params.get('session_id');
        } else {
            this.sessionId = null;
        }

        this.primaryColor = UTILS.getPrimaryColor();
        this.state = {
            cvv: '',
            valid_cvv: false,
            show_payment_success: false,
            show_loading: false,
            show_not_found: showNotFound,
            authentication_view_html: '',
            show_authentication_view: false,
            pay_button_clicked: false,
            is_3ds_required: false,
            amount: null,
            should_show_amount: false,
        };
    }

    async componentDidMount() {
        if (!this.state.show_not_found) {
            await this.getSessionInfo();
        }
    }

    async getSessionInfo() {
        this.setState({ "show_loading": true });
        await getTransactionSessionInfo(this.authToken, this.sessionId, this.onGetSessionInfosSuccess, this.onError);
    }

    onGetSessionInfosSuccess = (responseDto) => {
        this.setState({ "show_loading": false });
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (responseDto["session_status"] === SessionStatus.Completed) {
                this.setState({ "show_payment_completed": true });
            }

            this.setState({
                "is_3ds_required": responseDto["is_3ds_required"],
                "amount": responseDto["amount"],
                "should_show_amount": responseDto["should_show_amount"] ?? false,
            });
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
            window.location.replace(responseDto["failure_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
        }
        else {
            alert("Something went wrong, try again later");
        }
    }

    toQueryParameters(response) {
        return Object.keys(response)
            .filter(key => key !== 'redirection_url')
            .map(key => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(JSON.stringify(response[key]));
            }).join('&');
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    validateFormFields() {
        return this.validateCVV();
    }

    validateCVV() {
        const valid = this.state.cvv.length === 3;
        this.setState({ "valid_cvv": valid });
        return valid;
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ "pay_button_clicked": true });
        if (this.validateFormFields()) {
            if (this.state.is_3ds_required) {
                this.setState({ "show_loading": true });
                await getAuthenticationView({ "session_id": this.sessionId, }, this.authToken, this.onGetAuthViewSuccess, this.onError);
            } else {
                await this.pay();
            }
        }
    }

    async checkAuthenticationStatus() {
        await getAuthenticationStatus(this.authToken, this.sessionId, this.onGetAuthStatusSuccess, (error) => console.log(error));
    }

    async pay() {
        this.setState({ "show_loading": true });
        const requestDto = this.createPayRequestDto();
        await pay(requestDto, this.authToken, this.onPaySuccess, this.onError);
    }

    createPayRequestDto = () => {
        return { "security_code": this.state.cvv, "session_id": this.sessionId, };
    }

    ObjectContains = (dtoObject, internalObject) => {
        return dtoObject !== null && internalObject in dtoObject;
    }

    onGetAuthViewSuccess = (responseDto) => {
        this.setState({ "show_loading": false });
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            this.setState({ "show_authentication_view": true });
            this.setState({ "authentication_view_html": responseDto["otpHtmlContent"] });
            this.intervalId = setInterval(this.checkAuthenticationStatus.bind(this), 5000);
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
        }
        else {
            alert("Something went wrong");
        }
    }

    onGetAuthStatusSuccess = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (responseDto["status"] === "InProgress") {
                setTimeout(this.checkAuthenticationStatus, 5000);
            } else if (responseDto["status"] === "Successful") {
                clearInterval(this.intervalId);
                this.setState({ "show_authentication_view": false });
                this.pay();
            } else {
                clearInterval(this.intervalId);
                this.setState({ "show_authentication_view": false });
                alert("Payment failed!");
            }
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            this.setState({ "show_authentication_view": false });
            alert(responseDto["error_message"]);
        }
        else {
            this.setState({ "show_authentication_view": false });
            alert("Something went wrong, try again later");
        }
    }

    postMessage = (message) => {
        window.parent.postMessage(message, "*")
    }

    onPaySuccess = (responseDto) => {
        this.postMessage(responseDto);
        this.setState({ "show_loading": false });
        this.setState({ "show_authentication_view": false });
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (!responseDto["error_message"] && responseDto["transaction_status"] === TransactionStatus.Successful) {
                this.setState({ "show_payment_success": true });
                window.location.replace(responseDto["redirection_url"] + "?" + this.toQueryParameters(responseDto))
            } else {
                if (!responseDto["error_message"]) {
                    responseDto["error_message"] = "Payment failed";
                }

                window.location.replace(responseDto["redirection_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
            }
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
        }
        else {
            alert("Something went wrong");
        }
    }

    onError = (error) => {
        this.resetState();
        console.log(error);
        alert(error.message);
    }

    resetState() {
        this.setState({
            cvv: '',
            valid_cvv: false,
            show_payment_success: false,
            show_loading: false,
            show_not_found: false,
            authentication_view_html: '',
            show_authentication_view: false,
            pay_button_clicked: false,
            should_show_amount: false,
        })
    }

    showPaymentSuccess() {
        return (
            <div>
                <h3 className='text-center text-success mt-4'>Payment succeeded!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    showNotFound() {
        return (
            <div>
                <h3 className='text-center text-danger mt-4'>Payment not found!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    validateNumeric(event) {
        const value = event.target.value;
        if (value.length === 0) return;

        const lastDigit = value[value.length - 1];
        const reg = new RegExp('^[0-9]+$');
        if (!reg.test(lastDigit)) {
            event.target.value = value.slice(0, -1);
        }
    }

    handleBorderDangerClass(valid) {
        return valid || !this.state.pay_button_clicked ? '' : 'border-danger'
    }

    handleShowAmount() {
        return this.state.should_show_amount === true && this.state.amount !== null ?
            `${this.state.amount.toFixed(2)} EGP` : '';
    }

    showForm() {
        return (
            <>
                <Form className="ml-3 mt-2">
                    <Form.Group controlId="cvv" className="pt-3">
                        <Form.Control className={this.handleBorderDangerClass(this.state.valid_cvv)} type="text" placeholder="CVV" maxLength={3} onInput={(event) => this.validateNumeric(event)} onChange={this.handleChange} />
                    </Form.Group>
                </Form>
                <br />
                <div className="text-center">
                    <button className="btn btn-md pl-4 pr-4 primary-btn" style={{ width: "50%" }} onClick={(e) => this.onSubmit(e)}>
                        Pay {this.handleShowAmount()}
                    </button>
                </div>
                <br />
                <PoweredByBox />
                <br />
            </>)
    }

    showAuthenticationView() {
        return (
            <div>
                <iframe title="OTP Verfication" className="iframe-card" id="iframe" srcDoc={this.state.authentication_view_html}></iframe>
            </div>
        )
    }

    showLoading() {
        return (
            <div>
                <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <ReactLoading type={"spinningBubbles"} height={80} width={80} color={this.primaryColor} />
                </div>
            </div>
        )
    }

    getMainClassName() {
        if (this.state.show_loading !== true) {
            return "col-lg-5 p-4 main-box";
        }

        return "col-lg-5 p-4";
    }

    render() {
        return (
            <Container style={{ marginTop: "50px" }}>
                <div className="row p-4">
                    <div className='col-lg-3'></div>
                    <div className={this.getMainClassName()}>
                        {
                            this.state.show_not_found ? this.showNotFound() :
                                this.state.show_loading ? this.showLoading() :
                                    this.state.show_authentication_view ? this.showAuthenticationView() :
                                        this.state.show_payment_success ? this.showPaymentSuccess() : this.showForm()
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

export default DirectPaymentCheckout;