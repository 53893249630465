import React from 'react';
import { Container, Form } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import './paymentCheckout.css';
import UTILS from '../common/utils';
import PoweredByBox from '../common/poweredByBox';
import ApiClient from '../ApiClient';

const { getLiveLinkDetails, initLiveLinkPayment } = ApiClient();

class LiveLinkCheckout extends React.Component {
    constructor(props) {
        super(props);
        let showNotFound = false;
        let params = new URLSearchParams(window.location.search);
        if (params.has('order')) {
            this.encryptedOrder = params.get('order');
        } else {
            showNotFound = true;
        }

        this.primaryColor = UTILS.getPrimaryColor();
        this.state = {
            loading: true,
            order: null,
            show_not_found: showNotFound,
            customer_name: '',
            customer_email: '',
            customer_phone: '',
            customer_address: '',
        };
    }

    async componentDidMount() {
        if (!this.state.show_not_found) {
            await this.getOrderDetails();
        }
    }

    getProfileName = () => {
        let paths = window.location.pathname.split("/");
        let profileName = paths.pop();
        while (profileName === '') {
            profileName = paths.pop();
        }

        return profileName;
    }

    getOrderDetails = async () => {
        this.setState({ loading: true });
        const profileName = this.getProfileName();
        await getLiveLinkDetails(profileName, this.encryptedOrder, this.onGetOrderDetailsSuccess, this.onError)
    }

    onGetOrderDetailsSuccess = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            this.setState({ order: responseDto["response"] });
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
        }
        else {
            alert("Something went wrong, try again later");
        }

        this.setState({ loading: false })
    }

    getPaymentCheckout = async () => {
        this.setState({ loading: true });
        const profileName = this.getProfileName();
        const requestDto = {
            customer_name: this.state.customer_name,
            customer_email: this.state.customer_email,
            customer_phone: this.state.customer_phone,
            customer_address: this.state.customer_address,
        }

        await initLiveLinkPayment(profileName, requestDto, this.encryptedOrder, this.onGetPaymentCheckout, this.onError)
    }

    onGetPaymentCheckout = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            window.open(responseDto["response"]["checkout_url"], '_blank').focus();

        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
        }
        else {
            alert("Something went wrong, try again later");
        }

        this.setState({ loading: false })
    }

    ObjectContains = (dtoObject, internalObject) => {
        return dtoObject !== null && internalObject in dtoObject;
    }

    onError = (error) => {
        this.setState({ loading: false });
        console.log(error);
        alert(error.message);
    }

    showNotFound() {
        return (
            <div className='main-box p-4'>
                <h3 className='text-center text-danger mt-4'>Not found!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    showLoading() {
        return (
            <div>
                <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <ReactLoading type={"spinningBubbles"} height={80} width={80} color={this.primaryColor} />
                </div>
            </div>
        )
    }

    getMainClassName() {
        if (this.state.loading !== true) {
            return "col-lg-12 p-4 main-box";
        }

        return "col-lg-12 p-4";
    }

    productCard = (product) => {
        return (
            <div key={product.id} className="product-card col-lg-3 p-3">
                <div className='row'>
                    <div className='col-12 text-center' style={{ height: 300 }}>
                        <img style={{ height: 'auto', width: 'auto', maxWidth: 200, maxHeight: 300 }} src={product.image_link} alt={"Product"} />
                    </div>
                </div>
                <hr />
                <div className="row mt-1">
                    <h3 className='col-12'>{product.name}</h3>
                </div>
                <div className='row mt-2'>
                    <p className='col-12'>{product.description}</p>
                </div>
                <div className='row'>
                    <p>Price: <span className='price-color'>{product.price.toFixed(2)} EGP</span></p>
                </div>
            </div>
        )
    }

    handleCustomerInfoChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    customerForm = () => {
        return (
            <div className='main-box p-4 mt-4'>
                <div className='row mt-4 text-center'>
                    <h2>Customer Information</h2>
                </div>
                <Form className="ml-3 mt-2">
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group controlId="customer_name" className="pt-4">
                                <Form.Control type="text" maxLength={50} placeholder="Name" onChange={this.handleCustomerInfoChange} />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="customer_email" className="pt-4">
                                <Form.Control type="email" maxLength={50} placeholder="Email" onChange={this.handleCustomerInfoChange} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group controlId="customer_phone" className="pt-4">
                                <Form.Control type="tel" maxLength={12} placeholder="Phone Number" onChange={this.handleCustomerInfoChange} />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="customer_address" className="pt-4">
                                <Form.Control type="text" maxLength={50} placeholder="Address" onChange={this.handleCustomerInfoChange} />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                <br />
            </div>)
    }

    getOrderTotalAmount = () => {
        let sum = 0;
        this.state.order.products.forEach(product => {
            sum += product.price;
        });
        return sum;
    }

    showOrderDetails = () => {
        if (this.state.order === null) return null;

        return (
            <Container>
                <div className='row mt-2 text-center'>
                    <h2>Products</h2>
                </div>
                <br />
                <br />
                <div className='row mt-4'>
                    {this.state.order.products.map(this.productCard)}
                </div>
                <br />
                <br />
                <div className='row'>
                    {this.customerForm()}
                </div>
                <br />
                <div className='text-center mt-4'>
                    <button className="btn btn-md primary-btn" onClick={() => this.getPaymentCheckout()}>
                        Proceed to pay {this.state.order !== null ? `${this.getOrderTotalAmount().toFixed(2)} EGP` : ''}
                    </button>
                </div>
                <br />
                <br />
                <PoweredByBox />
                <br />
                <br />
            </Container>
        );
    }

    render() {
        return (
            <Container style={{ marginTop: "50px" }}>
                <div className="row">
                    <div className={this.getMainClassName()}>
                        {
                            this.state.show_not_found ? this.showNotFound() :
                                this.state.loading ? this.showLoading() :
                                    this.showOrderDetails()
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

export default LiveLinkCheckout;