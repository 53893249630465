import React from 'react';
import { Container } from 'react-bootstrap';
import PoweredByBox from '../common/poweredByBox';


export default function SuccessfulPayment() {
    return (
        <Container style={{ marginTop: "50px" }}>
            <div className="row p-4">
                <div className='col-lg-3'></div>
                <div className='col-lg-6 p-4 main-box'>
                    <div>
                        <h3 className='text-center text-success mt-4'>Payment completed successfully!</h3>
                        <br />
                        <PoweredByBox />
                        <br />
                    </div>
                </div>
            </div>
        </Container >
    );
}