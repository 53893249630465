import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import PaymentMethodCheckout from './pages/paymentMethodCheckout';
import DirectPaymentCheckout from './pages/directPaymentCheckout';
import HostedPaymentCheckout from './pages/hostedPaymentCheckout';
import LiveLinkCheckout from './pages/LiveLinkCheckout';
import SuccessfulPayment from './pages/successfulPayment';
import FailedPayment from './pages/failedPayment';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path='/hosted/payment' element={<HostedPaymentCheckout />} />
          <Route exact path='/direct/payment' element={<DirectPaymentCheckout />} />
          <Route exact path='/method' element={<PaymentMethodCheckout />} />
          <Route exact path='/live/*' element={<LiveLinkCheckout />} />
          <Route exact path='/payment/success' element={<SuccessfulPayment />} />
          <Route exact path='/payment/failure' element={<FailedPayment />} />
        </Routes>
      </Router>);
  }
}

export default App;