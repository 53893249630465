import UTILS from "./utils";

export default function PoweredByBox() {
    const renderLogo = () => {
        if (UTILS.isSahlDomain()) {
            return <span className="m-2"><img alt="sahl-logo"
            src="https://public-assets-prod-eu-west-1.s3.eu-west-1.amazonaws.com/images/sahl-v2.png"
            className="sahl-logo" /> </span>
        } else {
            return <strong className='primary-color'>TRXACT</strong>;
        }
    }

    return (
        <div className="text-center pt-2 mt-2">
            <span>Powered By </span> {renderLogo()}
        </div>
    );
}