import React from 'react';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ApiClient from '../ApiClient';
import ReactLoading from 'react-loading';
import './paymentCheckout.css';
import { PaymentMethodType, SessionStatus, TransactionStatus } from '../common/constans';
import UTILS from '../common/utils';
import PoweredByBox from '../common/poweredByBox';


const { pay, getAuthenticationView, getAuthenticationStatus, getTransactionSessionInfo } = ApiClient();

class HostedPaymentCheckout extends React.Component {
    constructor(props) {
        super(props);
        let showNotFound = false;
        let params = new URLSearchParams(window.location.search);
        if (params.has('token')) {
            this.authToken = params.get('token');
        } else {
            showNotFound = true;
        }
        if (params.has('session_id')) {
            this.sessionId = params.get('session_id');
        } else {
            this.sessionId = null;
        }

        this.primaryColor = UTILS.getPrimaryColor();

        this.state = {
            payment_number: '',
            valid_payment_number: false,
            expiry_date: '',
            valid_expiration_month: false,
            valid_expiration_year: false,
            first_name: '',
            valid_first_name: false,
            last_name: '',
            valid_last_name: false,
            cvv: '',
            valid_cvv: false,
            show_payment_success: false,
            show_loading: false,
            show_not_found: showNotFound,
            authentication_view_html: '',
            show_authentication_view: false,
            pay_button_clicked: false,
            payment_method_type: PaymentMethodType.Unknown,
            is_bank_card_enabled: false,
            is_meeza_card_enabled: false,
            is_wallet_enabled: false,
            show_payment_completed: false,
            is_3ds_required: false,
            should_save_payment_method: false,
            success_url: '',
            failure_url: '',
            amount: null,
            is_save_card_checkbox_enabled: false,
            should_show_amount: false,
        };
    }

    async componentDidMount() {
        if (!this.state.show_not_found) {
            await this.getSessionInfo();
        }
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    validateFormFields() {
        if (this.state.payment_method_type === PaymentMethodType.Wallet) {
            return this.validateWalletNumber();
        }

        return this.validatePaymentNumber() &
            this.validateExpirationMonth() &
            this.validateExpirationYear() &
            this.validateCVV() &
            this.validateFirstName() &
            this.validateLastName();
    }

    validateWalletNumber() {
        const valid = this.state.payment_number.length > 0;
        this.setState({ "valid_payment_number": valid });
        return valid;
    }

    validatePaymentNumber() {
        // 19 because of the 3 separated spaces
        const valid = this.state.payment_number.length === 19;
        this.setState({ "valid_payment_number": valid });
        return valid;
    }

    validateFirstName() {
        const valid = this.state.first_name.length > 0;
        this.setState({ "valid_first_name": valid });
        return valid;
    }

    validateLastName() {
        const valid = this.state.last_name.length > 0;
        this.setState({ "valid_last_name": valid });
        return valid;
    }

    getExpirationMonth() {
        return +this.state.expiry_date.substring(0, 2);
    }

    getExpirationYear() {
        const year = Math.floor(new Date().getFullYear() / 100) * 100;
        return year + (+this.state.expiry_date.substring(3, 5));
    }

    validateExpirationMonth() {
        const month = this.getExpirationMonth();
        const valid = month >= 1 && month <= 12;
        this.setState({ "valid_expiration_month": valid });
        return valid;
    }

    validateExpirationYear() {
        const valid = this.state.expiry_date.length === 5;
        this.setState({ "valid_expiration_year": valid });
        return valid;
    }

    validateCVV() {
        const valid = this.state.cvv.length === 3;
        this.setState({ "valid_cvv": valid });
        return valid;
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ "pay_button_clicked": true });
        if (this.validateFormFields()) {
            if (this.state.is_3ds_required || this.state.payment_method_type === PaymentMethodType.Wallet) {
                await this.getAuthView();
            } else {
                await this.pay();
            }
        }
    }

    async getAuthView() {
        const requestDto = this.createGetAuthenticationViewRequestDto();
        this.setState({ "show_loading": true });
        await getAuthenticationView(requestDto, this.authToken, this.onGetAuthViewSuccess, this.onError);
    }

    async checkAuthenticationStatus() {
        await getAuthenticationStatus(this.authToken, this.sessionId, this.onGetAuthStatusSuccess, (error) => console.log(error));
    }

    async getSessionInfo() {
        this.setState({ "show_loading": true });
        await getTransactionSessionInfo(this.authToken, this.sessionId, this.onGetSessionInfosSuccess, this.onError);
    }

    async pay() {
        this.setState({ "show_loading": true });
        const requestDto = this.createPayRequestDto();
        await pay(requestDto, this.authToken, this.onPaySuccess, this.onError);
    }

    createPayRequestDto = () => {
        return {
            "payment_number": this.state.payment_number.replace(/ /g, ''),
            "expiration_month": this.getExpirationMonth(),
            "expiration_year": this.getExpirationYear(),
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "security_code": this.state.cvv,
            "should_save_payment_method": this.state.should_save_payment_method,
            "session_id": this.sessionId,
        }
    }

    postMessage = (message) => {
        window.parent.postMessage(message, "*")
    }

    createGetAuthenticationViewRequestDto = () => {
        return {
            "payment_number": this.state.payment_number.replace(/ /g, ''),
            "expiration_month": this.getExpirationMonth(),
            "expiration_year": this.getExpirationYear(),
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "session_id": this.sessionId,
        }
    }

    ObjectContains = (dtoObject, internalObject) => {
        return dtoObject !== null && internalObject in dtoObject;
    }

    onGetAuthViewSuccess = (responseDto) => {
        this.setState({ "show_loading": false });
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            this.setState({ "show_authentication_view": true });
            this.setState({ "authentication_view_html": responseDto["otpHtmlContent"] });
            this.intervalId = setInterval(this.checkAuthenticationStatus.bind(this), 5000);
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
            window.location.replace(this.state.failure_url + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
        }
        else {
            alert("Something went wrong");
        }
    }

    onGetAuthStatusSuccess = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (responseDto["status"] === "InProgress") {
                setTimeout(this.checkAuthenticationStatus, 5000);
            } else if (responseDto["status"] === "Successful") {
                clearInterval(this.intervalId);
                this.setState({ "show_authentication_view": false });
                this.pay();
            } else {
                clearInterval(this.intervalId);
                this.setState({ "show_authentication_view": false });
                alert("Payment failed!");
                window.location.replace(this.state.failure_url + "error_message=OTP verification failed");
            }
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            this.setState({ "show_authentication_view": false });
            alert(responseDto["error_message"]);
        }
        else {
            this.setState({ "show_authentication_view": false });
            alert("Something went wrong, try again later");
        }
    }

    onGetSessionInfosSuccess = (responseDto) => {
        this.setState({ "show_loading": false });
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (responseDto["session_status"] === SessionStatus.Completed) {
                this.setState({ "show_payment_completed": true });
                return;
            }

            if (!responseDto["is_bank_card_enabled"] &&
                !responseDto["is_meeza_card_enabled"] &&
                !responseDto["is_wallet_enabled"]) {
                alert('No payment method is allowed for you');
                this.setState({ "show_not_found": true });
            } else {
                let paymentMethodType = PaymentMethodType.Unknown;
                if (responseDto["is_bank_card_enabled"]) {
                    paymentMethodType = PaymentMethodType.BankCard;
                } else if (responseDto["is_meeza_card_enabled"]) {
                    paymentMethodType = PaymentMethodType.MeezaCard;
                } else if (responseDto["is_wallet_enabled"]) {
                    paymentMethodType = PaymentMethodType.Wallet;
                }

                this.setState({ "payment_method_type": paymentMethodType })
            }

            this.setState({
                "is_save_card_checkbox_enabled": responseDto["is_save_card_checkbox_enabled"],
                "is_bank_card_enabled": responseDto["is_bank_card_enabled"],
                "is_meeza_card_enabled": responseDto["is_meeza_card_enabled"],
                "is_wallet_enabled": responseDto["is_wallet_enabled"],
                "is_3ds_required": responseDto["is_3ds_required"],
                "success_url": responseDto["success_url"],
                "failure_url": responseDto["failure_url"],
                "amount": responseDto["amount"],
                "should_show_amount": responseDto["should_show_amount"]
            });
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
            window.location.replace(responseDto["failure_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
        }
        else {
            alert("Something went wrong, try again later");
        }
    }

    toQueryParameters(response) {
        return Object.keys(response)
            .filter(key => key !== 'redirection_url')
            .map(key => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(JSON.stringify(response[key]));
            }).join('&');
    }

    onPaySuccess = (responseDto) => {
        this.postMessage(responseDto);
        this.setState({ "show_loading": false });
        this.setState({ "show_authentication_view": false });
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (!responseDto["error_message"] && responseDto["transaction_status"] === TransactionStatus.Successful) {
                this.setState({ "show_payment_success": true });
                window.location.replace(responseDto["redirection_url"] + "?" + this.toQueryParameters(responseDto))
            } else {
                if (!responseDto["error_message"]) {
                    responseDto["error_message"] = "Payment failed";
                }

                window.location.replace(responseDto["redirection_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
            }
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            alert(responseDto["error_message"]);
        }
        else {
            alert("Something went wrong");
        }
    }

    onError = (error) => {
        this.resetState();
        console.log(error);
        alert(error.message);
    }

    resetState() {
        this.setState({
            payment_number: '',
            valid_payment_number: false,
            expiry_date: '',
            valid_expiration_month: false,
            valid_expiration_year: false,
            first_name: '',
            valid_first_name: false,
            last_name: '',
            valid_last_name: false,
            cvv: '',
            valid_cvv: false,
            show_payment_success: false,
            show_loading: false,
            show_not_found: false,
            authentication_view_html: '',
            show_authentication_view: false,
            pay_button_clicked: false,
            should_show_amount: false,
        })
    }

    showPaymentSuccess() {
        return (
            <div>
                <h3 className='text-center text-success mt-4'>Payment succeeded!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    showNotFound() {
        return (
            <div>
                <h3 className='text-center text-danger mt-4'>Payment not found!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    showPaymentCompleted() {
        return (
            <div>
                <h3 className='text-center text-danger mt-4'>Payment has been completed before!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    validateNumeric(event) {
        const value = event.target.value;
        if (value.length === 0) return;

        const lastDigit = value[value.length - 1];
        const reg = new RegExp('^[0-9]+$');
        if (!reg.test(lastDigit)) {
            event.target.value = value.slice(0, -1);
        }
    }

    formatCardNumber(event) {
        let value = event.target.value.replace(/ /g, '');
        if (value.length < 4) return;
        let splittedArr = value.match(/.{1,4}/g);
        event.target.value = splittedArr.join(' ');
    }

    formatExpiryDate(event) {
        let date = event.target.value;
        if (date.length === 2 && this.state.expiry_date.length === 1) {
            event.target.value = date + '/';
        }
        else if (date.length === 3 && this.state.expiry_date.length === 2) {
            event.target.value = this.state.expiry_date + '/' + date[date.length - 1];
        }
    }

    handleBorderDangerClass(valid) {
        return valid || !this.state.pay_button_clicked ? '' : 'border-danger'
    }

    handleShowAmount() {
        return this.state.should_show_amount === true && this.state.amount !== null ?
            `${this.state.amount.toFixed(2)} EGP` : '';
    }

    showPaymentCardForm() {
        return (
            <div>
                <Form className="ml-3 mt-2">
                    <Form.Group controlId="payment_number" className="pt-4" >
                        <Form.Control className={this.handleBorderDangerClass(this.state.valid_payment_number)} type="text" onInput={(event) => { this.validateNumeric(event); this.formatCardNumber(event); }} maxLength={19} placeholder="Card Number" onChange={this.handleChange} />
                    </Form.Group>
                    <div className="row">
                        <div className="col-6">
                            <Form.Group controlId="first_name" className="pt-4">
                                <Form.Control className={this.handleBorderDangerClass(this.state.valid_first_name)} type="text" maxLength={50} placeholder="first name" onChange={this.handleChange} />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group controlId="last_name" className="pt-4">
                                <Form.Control className={this.handleBorderDangerClass(this.state.valid_last_name)} type="text" maxLength={50} placeholder="last name" onChange={this.handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Form.Group controlId="expiry_date" className="pt-4">
                                <Form.Control className={this.handleBorderDangerClass(this.state.valid_expiration_month && this.state.valid_expiration_year)} type="text" maxLength={5} onInput={(event) => { this.validateNumeric(event); this.formatExpiryDate(event); }} placeholder="MM/YY" onChange={this.handleChange} />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group controlId="cvv" className="pt-4">
                                <Form.Control className={this.handleBorderDangerClass(this.state.valid_cvv)} type="text" placeholder="CVV" maxLength={3} onInput={(event) => this.validateNumeric(event)} onChange={this.handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                    {
                        !this.state.is_save_card_checkbox_enabled ? null :
                            (
                                <Form.Group controlId="should_save_payment_method" className="pt-4">
                                    <Form.Check style={{ marginLeft: '40%' }} type="checkbox" id="custom-switch" label="Save card" onChange={() => this.setState({ should_save_payment_method: !this.state.should_save_payment_method })} />
                                </Form.Group>
                            )
                    }
                </Form>
                <br />
                <div className='text-center'>
                    <button className="btn btn-md pl-4 pr-4 primary-btn" style={{ width: "50%" }} onClick={(e) => this.onSubmit(e)}>
                        Pay {this.handleShowAmount()}
                    </button>
                </div>
                <br />
                <PoweredByBox />
                <br />
            </div>)
    }

    showWalletForm() {
        return (
            <div>
                <Form className="ml-3 mt-2">
                    <Form.Group controlId="payment_number" className="pt-4" >
                        <Form.Control className={this.handleBorderDangerClass(this.state.valid_payment_number)} type="text" onInput={(event) => { this.validateNumeric(event); }} maxLength={19} placeholder="Wallet Number" onChange={this.handleChange} />
                    </Form.Group>
                </Form>
                <br />
                <div className="text-center">
                    <button className="btn btn-md primary-btn pl-4 pr-4" style={{ width: "50%" }} onClick={(e) => this.onSubmit(e)}>
                        Pay {this.handleShowAmount()}
                    </button>
                </div>
                <br />
                <PoweredByBox />
                <br />
            </div>)
    }

    showAuthenticationView() {
        if (this.state.payment_method_type === PaymentMethodType.Wallet) {
            return (
                <div>
                    <div className='row mt-4'>
                        <h4>Confirm the payment request with your wallet</h4>
                    </div>
                    <div className='row mt-4'>
                        <p>We sent a request to your wallet provider. You will find it in your wallet application or you will get an SMS with instructions to follow</p>
                    </div>
                    <br />
                    <div className='row mt-2'>
                        <div
                            style={{
                                width: "100%",
                                height: "100",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <ReactLoading type={"spin"} height={70} width={70} color={this.primaryColor} />
                        </div>
                    </div>
                    <br />
                    <div className='row mt-4 pb-2'>
                        <h4>Awaiting payment confirmation</h4>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <iframe title="OTP Verfication" className="iframe-card" id="iframe" srcDoc={this.state.authentication_view_html}></iframe>
                </div>
            )
        }
    }

    showLoading() {
        return (
            <div>
                <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <ReactLoading type={"spinningBubbles"} height={80} width={80} color={this.primaryColor} />
                </div>
            </div>
        )
    }

    getCardBackgroudColor(paymentMethod) {
        return this.state.payment_method_type === paymentMethod ? this.primaryColor : "#f0f0f0"
    }

    getCardTextColor(paymentMethod) {
        return this.state.payment_method_type === paymentMethod ? "#fff" : "#454545"
    }

    changeSelectedPaymentMethod(value) {
        this.setState({ payment_method_type: value });
    }

    showTab(paymentMethodType, isBankCardEnabled, isMeezaCardEnabled, isWalletEnabled, label, borderStyle = 'none') {
        const count = (isBankCardEnabled | isMeezaCardEnabled) + isWalletEnabled;
        const col = 12 / count;
        let className = `col-${col}`;

        return (
            <div className={className + " btn btn-md"} style={{ backgroundColor: this.getCardBackgroudColor(paymentMethodType), color: this.getCardTextColor(paymentMethodType), borderRadius: 0, borderLeft: `1px ${borderStyle} #EAEAEA` }} onClick={() => this.changeSelectedPaymentMethod(paymentMethodType)}>
                {label}
            </div>
        );
    }

    showForm() {
        if (this.state.payment_method_type === PaymentMethodType.Unknown) {
            return null;
        }

        return (
            <div>
                <div className="row">
                    {
                        this.state.is_bank_card_enabled || this.state.is_meeza_card_enabled ? this.showTab(PaymentMethodType.BankCard, this.state.is_bank_card_enabled, this.state.is_meeza_card_enabled, this.state.is_wallet_enabled, "Bank/Meeza Card") : null
                    }
                    {
                        this.state.is_wallet_enabled ? this.showTab(PaymentMethodType.Wallet, this.state.is_bank_card_enabled, this.state.is_meeza_card_enabled, this.state.is_wallet_enabled, "Digital Wallet", this.state.is_meeza_card_enabled ? 'solid' : 'none') : null
                    }
                </div>
                <div className="row mt-3">
                    {
                        this.state.payment_method_type === PaymentMethodType.Wallet
                            ? this.showWalletForm()
                            : this.showPaymentCardForm()
                    }
                </div>
            </div>
        )
    }

    getMainClassName() {
        if (this.state.show_loading !== true) {
            return "col-lg-5 p-4 main-box";
        }

        return "col-lg-5 p-4";
    }

    render() {
        return (
            <Container style={{ marginTop: "50px" }}>
                <div className="row p-4">
                    <div className='col-lg-3'></div>
                    <div className={this.getMainClassName()}>
                        {
                            this.state.show_not_found ? this.showNotFound() :
                                this.state.show_payment_completed ? this.showPaymentCompleted() :
                                    this.state.show_loading ? this.showLoading() :
                                        this.state.show_authentication_view ? this.showAuthenticationView() :
                                            this.state.show_payment_success ? this.showPaymentSuccess() : this.showForm()
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

export default HostedPaymentCheckout;