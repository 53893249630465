const UTILS = {
    getPrimaryColor() {
        return this.isSahlDomain() ? '#4055FF' : '#4055FF'
    },
    isSahlDomain() {
        return window.location.href.includes('sahl');
    },
}

export default UTILS;
