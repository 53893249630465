export const PaymentMethodType = {
    Unknown: 0,
    BankCard: 1,
    MeezaCard: 2,
    Wallet: 3,
}

export const SessionStatus = {
    InProgress: 1,
    Completed: 2,
}

export const TransactionStatus = {
    InProgress: 1,
    Successful: 2,
    Failed: 3,
    Refunded: 4,
    PendingAuthentication: 5,
    Authenticated: 6
}